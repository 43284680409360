<template>
<div class="cursor-pointer dropdown-custom relative" ref="dropdownTry" @click="toggleClick()">
  <div class="flex items-center">
    <div class="mr-2">
      <slot name="container">
      </slot>
    </div>
    <div :class="classActive()" v-if="iconArrow" v-html="$icon.svg.arrowDownwardFillEva">
    </div>
  </div>
  <div :style="styleContent" ref="dropdownContent">
    <slot name="content">
    </slot>
  </div>
</div>
</template>

<script>
export default {
  name: 'DropdownAbsolute',
  props: {
    iconArrow: {
      type: Boolean,
      default: () => false
    },
    marginTop: {
      type: String,
      default: () => '0px'
    },
    position: {
      type: String,
      default: () => 'bottomRight'
    }
  },
  data() {
    return {
      active: false,
      styleContent: {
        display: 'none',
        position: 'absolute',
        overflow: 'hidden',
        height: 0,
        zIndex: 999,
        transition: 'all .5s ease-in-out',
        top: '100%',
        right: 0
      }
    }
  },
  methods: {
    classActive() {
      return (this.active) ? 'down-arrow rotate' : 'down-arrow'
    },
    toggleClick() {
      this.active = !this.active
      const boundingClientDropdownTry = this.$refs.dropdownTry.getBoundingClientRect()

      if (this.active) {
        this.styleContent.display = 'inline-flex'
        // eslint-disable-next-line vue/require-slots-as-functions
        // this.$refs.dropdownContent.children[0].style.width = `${this.$refs.dropdownTry.clientWidth}px`
        this.styleContent.top = `${(boundingClientDropdownTry.y + boundingClientDropdownTry.height)}px`
        // this.styleContent.left = `${this.$refs.dropdownTry.offsetLeft - this.$refs.dropdownTry.clientWidth}px`
        this.styleContent.height = 'auto'
        console.log('dropdownContent', this.$refs.dropdownContent.firstElementChild.clientWidth)
      } else {
        this.styleContent.display = 'none'
        this.styleContent.height = 0
      }
    }
  }
}
</script>
